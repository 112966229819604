<template>
  <div class="container">
    <div class = "row">
      <div class = "col-12 col-md">
        <h2>Hi, I'm Spencer</h2> 
        <p>A junior software developer located in Alberta looking for some new ways to invest in myself and my learning.<br/>
          I enjoy working on projects that help people change the world. My interest in software development came 
          as a child when Neopets was at the height of its popularity.
          I was so jealous of all the other stores and pages that I had to learn 
          how to make my own page just as pretty as everyone elses was. That was my first experience with CSS and HTML.  
          <br/>
          From there, my interest in programming only grew stronger, as I learnt to use programming to make the robots 
          I enjoyed building better. 
          </p>
      </div>
      <div class="col-12 col-md-auto">
        <Profile/>
      </div>
    </div>
  </div>
  <div>
    <MySkills/>
  </div>
</template>

<script>
  import Profile from "@/components/Profile.vue";
  import MySkills from '../components/MySkills.vue';

  export default {
    components: {
      Profile,
      MySkills,
    }
  };
</script>

<style scoped>

</style>
